.modal {
   text-align: center;
   padding: 0!important;
 }

 .modal:before {
   content: '';
   display: inline-block;
   height: 100%;
   vertical-align: middle;
   margin-right: -4px;
 }

 .modal-dialog {
   display: inline-block;
   text-align: left;
   vertical-align: middle;
 }

 .form-control[disabled], .form-group .form-control[disabled], fieldset[disabled] .form-control, fieldset[disabled] .form-group .form-control {
    border-bottom: 0px dotted #D2D2D2 !important;
}

.quiet, .quiet a {
    font-size: 13px;
    color: #838c91;
}

.img-circle {
  box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.12), 0 1px 6px 0 rgba(0, 0, 0, 0.12) !important;
}

legend {
  margin-top: 15px;
  margin-bottom: 0px;

  font-size: 21px;
}

fieldset {
  margin-bottom: 50px;
}

.pagina {
  text-align: center;
  width: 100px;
  margin-left: -50px;

  position: absolute;
  left: 50%;
  top:15px;
  color: #ddd;

  display: block;
  font-weight: bold;

}

.relative { position: relative;}

.btn-thin {
  padding: 0px;
  margin: 0px;
}

.text-area-preview {
  white-space: pre-line;
}

.status-message {
  padding: 1.25rem;
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
  border: 1px solid #eee;
  border-left-width: .25rem;
  border-radius: .25rem;

  &.pending {
    border-left-color: #faa404;

    h4 {
      color: #faa404;
    }
  }
}

/* ############################ Loader ######################## */
#loader-background {
	position: fixed;
	left: 0px;
	top: 0px;
	width: 100%;
  height: 100%;
  opacity: 0.7;
  background-color: #fff;
  z-index: 9999;
}

div.loader-wrapper {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%)
}

.loader:before,
.loader:after,
.loader {
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load 1.8s infinite ease-in-out;
  animation: load 1.8s infinite ease-in-out;
}
.loader {
  margin: 8em auto;
  font-size: 12px;
  position: relative;
  text-indent: -9999em;
  -webkit-animation-delay: 0.16s;
  animation-delay: 0.16s;
}
.loader:before {
  left: -3.5em;
}
.loader:after {
  left: 3.5em;
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
}
.loader:before,
.loader:after {
  content: "";
  position: absolute;
  top: 0;
}
@-webkit-keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #1a7fc9;
  }
  40% {
    box-shadow: 0 2.5em 0 0 #1a7fc9;
  }
}
@keyframes load {
  0%,
  80%,
  100% {
    box-shadow: 0 2.5em 0 -1.3em #1a7fc9;

  }
  40% {
    box-shadow: 0 2.5em 0 0 #1a7fc9;
  }
}
/* ############################ Loader ######################## */

/* sweetalert2 fix for Bootstrap3 - see https: //github.com/sweetalert2/sweetalert2/issues/834 */
.swal2-popup {
  font-size: 1.6rem !important;
}

.slider-handle.custom {
  background: transparent none;
}

/* Make sure that padding behaves as expected */
* {box-sizing:border-box}

/* Container for skill bars */
.horizontal-bar-container {
    width: 100%; /* Full width */
    background-color: #ddd; /* Grey background */
}

.horizontal-bar-label {
    text-align: right; /* Right-align text */
    padding-right: 5px; /* Add some right padding */
    line-height: 15px; /* Set the line-height to center the text inside the skill bar, and to expand the height of the container */
    color: white; /* White text color */
}

#chartjs-tooltip {
  opacity: 1;
  position: absolute;
  background: rgba(0, 0, 0, .7);
  color: white;
  border-radius: 3px;
  -webkit-transition: all .1s ease;
  transition: all .1s ease;
  pointer-events: none;
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  z-index: 10;
}

.chartjs-tooltip-key {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
}

.dropzone {
  border: 1px solid #D2D2D2 !important;
}

.padding-xs {
  padding: .25em;
}

.padding-sm {
  padding: .5em;
}

.padding-md {
  padding: 1em;
}

.padding-lg {
  padding: 1.5em;
}

.padding-xl {
  padding: 3em;
}

.padding-x-xs {
  padding: .25em 0;
}

.padding-x-sm {
  padding: .5em 0;
}

.padding-x-md {
  padding: 1em 0;
}

.padding-x-lg {
  padding: 1.5em 0;
}

.padding-x-xl {
  padding: 3em 0;
}

.padding-y-xs {
  padding: 0 .25em;
}

.padding-y-sm {
  padding: 0 .5em;
}

.padding-y-md {
  padding: 0 1em;
}

.padding-y-lg {
  padding: 0 1.5em;
}

.padding-y-xl {
  padding: 0 3em;
}

.padding-top-xs {
  padding-top: .25em;
}

.padding-top-sm {
  padding-top: .5em;
}

.padding-top-md {
  padding-top: 1em;
}

.padding-top-lg {
  padding-top: 1.5em;
}

.padding-top-xl {
  padding-top: 3em;
}

.padding-right-xs {
  padding-right: .25em;
}

.padding-right-sm {
  padding-right: .5em;
}

.padding-right-md {
  padding-right: 1em;
}

.padding-right-lg {
  padding-right: 1.5em;
}

.padding-right-xl {
  padding-right: 3em;
}

.padding-bottom-xs {
  padding-bottom: .25em;
}

.padding-bottom-sm {
  padding-bottom: .5em;
}

.padding-bottom-md {
  padding-bottom: 1em;
}

.padding-bottom-lg {
  padding-bottom: 1.5em;
}

.padding-bottom-xl {
  padding-bottom: 3em;
}

.padding-left-xs {
  padding-left: .25em;
}

.padding-left-sm {
  padding-left: .5em;
}

.padding-left-md {
  padding-left: 1em;
}

.padding-left-lg {
  padding-left: 1.5em;
}

.padding-left-xl {
  padding-left: 3em;
}

.margin-xs {
  margin: .25em;
}

.margin-sm {
  margin: .5em;
}

.margin-md {
  margin: 1em;
}

.margin-lg {
  margin: 1.5em;
}

.margin-xl {
  margin: 3em;
}

.margin-x-xs {
  margin: .25em 0;
}

.margin-x-sm {
  margin: .5em 0;
}

.margin-x-md {
  margin: 1em 0;
}

.margin-x-lg {
  margin: 1.5em 0;
}

.margin-x-xl {
  margin: 3em 0;
}

.margin-y-xs {
  margin: 0 .25em;
}

.margin-y-sm {
  margin: 0 .5em;
}

.margin-y-md {
  margin: 0 1em;
}

.margin-y-lg {
  margin: 0 1.5em;
}

.margin-y-xl {
  margin: 0 3em;
}

.margin-top-xs {
  margin-top: .25em;
}

.margin-top-sm {
  margin-top: .5em;
}

.margin-top-md {
  margin-top: 1em;
}

.margin-top-lg {
  margin-top: 1.5em;
}

.margin-top-xl {
  margin-top: 3em;
}

.margin-right-xs {
  margin-right: .25em;
}

.margin-right-sm {
  margin-right: .5em;
}

.margin-right-md {
  margin-right: 1em;
}

.margin-right-lg {
  margin-right: 1.5em;
}

.margin-right-xl {
  margin-right: 3em;
}

.margin-bottom-xs {
  margin-bottom: .25em;
}

.margin-bottom-sm {
  margin-bottom: .5em;
}

.margin-bottom-md {
  margin-bottom: 1em;
}

.margin-bottom-lg {
  margin-bottom: 1.5em;
}

.margin-bottom-xl {
  margin-bottom: 3em;
}

.margin-left-xs {
  margin-left: .25em;
}

.margin-left-sm {
  margin-left: .5em;
}

.margin-left-md {
  margin-left: 1em;
}

.margin-left-lg {
  margin-left: 1.5em;
}

.margin-left-xl {
  margin-left: 3em;
}
