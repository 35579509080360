  .timeline {
    list-style-type: none;
    display: flex;

    justify-content: center;
  }

  .li {
    transition: all 200ms ease-in;
  }

  .timestamp {
    margin-top: 20px;
    padding: 0px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 100;
  }

  .status {
    padding: 0px 20px;
    display: flex;
    justify-content: center;
    border-bottom: 2px solid #D6DCE0;
    position: relative;
    transition: all 200ms ease-in;
  }
  .status h4 {
    font-size: 16px;
    font-weight: 600;
  }
  .status:before {
    content: "";
    width: 25px;
    height: 25px;
    background-color: white;
    border-radius: 25px;
    border: 1px solid #ddd;
    position: absolute;
    bottom: -15px;
    left: 42%;
    transition: all 200ms ease-in;
  }

  .li.todo .status {
    border-bottom: 2px solid rgb(218, 218, 218);
  }
  .li.todo .status:before {
    background-color:  rgb(218, 218, 218);
    border: none;
    transition: all 200ms ease-in;
  }
  .li.todo .status h4 {
    color: rgb(218, 218, 218);
  }
  .li.pending .status {
    border-bottom: 2px solid rgb(250, 164, 4);
  }
  .li.pending .status:before {
    background-color:  rgb(250, 164, 4);
    border: none;
    transition: all 200ms ease-in;
  }
  .li.complete .status {
    border-bottom: 2px solid #66DC71;
  }
  .li.complete .status:before {
    background-color: #66DC71;
    border: none;
    transition: all 200ms ease-in;
  }

  @media (min-width: 320px) and (max-width: 800px) {
    .timeline {
     padding-top:30px;
      display: block;

    }

    .li {
      padding-bottom: 10px;
      position: relative
    }

    .status {
      display:block;
      border-bottom: none !important;
      padding-left: 35px;


    }

    .timestamp {
      margin-top: 0;
      padding: 0px 35px;
      display:block;
      font-weight: 100;
    }

    .status:before {
      content: "";
      width: 25px;
      height: 25px;
      background-color: white;
      border-radius: 25px;
      border: 1px solid #ddd;
      position: absolute;
      top: -2px;
      left: 0;
      transition: all 200ms ease-in;
      z-index:2;
    }

    .li:after {
      content: '';
      width: 2px;
      position: absolute;
      top:-20px;
      bottom:-10px;

      left: 12px;

      background: #D6DCE0
    }

    .li.not-started:after { background: rgb(218, 218, 218); }

    .li.pending:after { background: rgb(250, 164, 4) }
    .li.complete:after { background:  #66DC71 }

    .li:last-child:after{
      display: none
    }

    .status h4 {
     margin-bottom:0
    }
  }


.timeline-legend {
  list-style:none;
  padding:0;
  margin:0;
}

.timeline-legend li {
  display: inline-block;
  margin-right: 10px
}
.timeline-legend li:last-child {
  margin-right:0;
}
.timeline-legend .status-bullet {
  content: '';
  display:inline-block;
  width:15px;
  height:15px;
  border-radius: 100px;

  margin-bottom: -2px
}


.status-todo {
  background: rgb(218, 218, 218);
}
.status-complete {
  background: #66DC71;
}
.status-pending {
  background: rgb(250, 164, 4);
}