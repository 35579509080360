@import "theme/variables";
@import "./timeline";

body {
    background-color: #FFFFFF;
}
.relative {
  position: relative;
}

.agis-azul {
  color: $brand-primary;
}

.agis-cinza {
  color: fadeout($black, 54%);
}

.btn-agis-azul {
  background-color: $brand-info !important;
  color: #ffffff !important;
}

.navbar-brand {
  padding: 0px;
}

.navbar-brand > img {
  height: 100%;
  padding: 15px;
  width: auto;
}

@media (max-width: 1200px) {
    .navbar-brand img {
        padding: 0px;
    }
}

.navbar.navbar-agis {
  background-color: #024ea2;
  color: #ffffff;
  // box-shadow: 0 1px 6px 0 rgba(0,0,0,.12), 0 1px 6px 0 rgba(0,0,0,.12);
  position: relative;
  z-index: 2;
}

///
.page-header--primary {
  background: $brand-primary;
  color: #fff;
  padding:40px 0 120px;
  margin-top: -20px;

  h1 {
    display: inline-block;
  }
  h1 small {
    color: #fff;
    opacity: 0.7
  }

  .breadcrumb {
    position: absolute;
    top: -40px;
    left: 0;
    right: 0;
    background: transparent;
    color: lighten($brand-primary, 40%);
    > li + li:before {
      color: lighten($brand-primary, 40%);;
    }
    a {
      color: $brand-warning;
    }
    > .active {
      color: lighten($brand-primary, 40%);
      font-weight: bold
    }
  }
}

.page-content {
  margin-top:-100px
}

#content, #filter {
  transition: all 0.2s ease-in-out
}

#filter {
  position: absolute;
  overflow: hidden;
  right: 0;
  top: -100px;
  opacity: 1;

  &.inactive {
    width: 0;
    height: 0;
    opacity: 0;
  }
}

.panel-body {
  padding: 20px
}



///

.btn-info:active, .btn-info.active, .open > .dropdown-toggle.btn-info {
  color: #fff
}


.btn-info {
  color: #FFFFFF ;
  background-color: #024EA2;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  color: #FFFFFF;
  background-color: #026AA2 ;
  border-color: #FFFFFF;
}

.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-image: none;
}

.btn-info.disabled,
.btn-info[disabled],
fieldset[disabled] .btn-info,
.btn-info.disabled:hover,
.btn-info[disabled]:hover,
fieldset[disabled] .btn-info:hover,
.btn-info.disabled:focus,
.btn-info[disabled]:focus,
fieldset[disabled] .btn-info:focus,
.btn-info.disabled:active,
.btn-info[disabled]:active,
fieldset[disabled] .btn-info:active,
.btn-info.disabled.active,
.btn-info[disabled].active,
fieldset[disabled] .btn-info.active {
  background-color: #024EA2;
  border-color: #FFFFFF;
}

.btn-info .badge {
  color: #024EA2 ;
  background-color: #FFFFFF;
}


.list-group .list-group-item {
  padding: 16px;
  overflow: visible;
  border-bottom: 1px solid #f5f5f5;

  transition: all 0.2s ease-in-out;

  &:hover {
    cursor: default;
    background: #f9f9f9;
  }
}


.dropdown-options {
  display: inline-block
}

.menu-local.nav-pills {
  border:0;
  background: transparent;
  display: inline-block;
  position: relative;
  top: 10px;
  left: 10px;

  a {
    color: #fff;
    font-size: 16px;
    transition: all 0.2s ease-in-out;
    border-radius: 0;

    &:hover,
    &:active,
    &:focus {
      background: $brand-info !important;
      color: #fff;
    }
  }
  .active {
    a {
      background: transparent;

      font-weight: bold !important;
      border-bottom: 2px solid $brand-info;
    }
  }
}

.page-title {
  margin:0;
  padding-bottom: 0
}

.page-actions {
  padding-top:5px;
}

.subtitulo h2 {
  font-size: 25px;
}

.table > tbody > tr > td {
     vertical-align: middle;
}



////// Status Messages

$pending:  #faa404;

.status-message {
  padding: 20px;
  margin: 20px 0;
  border: 1px solid #eee;
  border-left-width: 5px;
  border-radius: 3px;

  &.pending {
    border-left-color: $pending;

    h4 {
      color: darken($pending, 5%);
      margin-top: 0;
      margin-bottom: 5px;
      font-size: 18px;
      font-weight: 500;
    }
  }
  p {
    margin: 0 0 10px;
  }
  p:last-child {
    margin-bottom: 0;
  }
}

//paleta de cor
#colorPallet {
    margin: 0;
    padding: 0;
    list-style: none;
    margin-top: 60px;
    li {
        display: block;
        width: 30px;
        height: 30px;
        line-height: 30px;
        text-align: center;
        float:left;
        margin: 0 5px 0 0;
        border-radius: 30px;
        border:3px solid #fff;
        color: #fff;
        transition: all 0.3s ease-in-out;
        cursor: pointer;
        i { display: none; line-height: 25px;}
        &.yellow { background-color: #feca57; }
        &.blue { background-color: #0abde3; }
        &.purple { background-color: #be2edd; }
        &.pink { background-color: #f368e0; }
        &.orange { background-color: #ff9f43; }
        &.red { background-color: #ee5253; }
        &.green { background-color:#10ac84; }
        &.primary { background-color: #024ea2; }
        &:hover {
            border-color: rgba(#333, 0.3);
        }
        &.active {
          i {display: block;}
        }
    }
}

#colorSelector { text-transform: uppercase}

#iconPreview {
    display: block;
    width: 50px;
    height: 50px;
    line-height: 50px;
    position: relative;
    color: #FFF;
    font-size: 40px;
    font-weight: 300;
    text-align: center;
    background-color: rgb(79, 191, 132);
    position: absolute;
    top:-51px;
    left:0;
}


.dropdown-menu {
  margin-top:-5px;
  margin-left: 1px;
}


select {
  border-bottom: solid 1px #D2D2D2 !important;
  background: url("../images/arrow.svg") no-repeat right center !important;

  &:focus {
    border-bottom: solid 1px #024EA2 !important;
  }
}

// multiselect
.ms-container {
  width: 100%
}

.col-md-3,
.modal-body .row div:last-child,
.is-fileinput {
  > button,
  .bootstrap-filestyle {
    margin-top: 58px
  }
}

.col-md-12 > h4 {
  margin-top: 50px;
}

.truncate {
  width: 800px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-wrap-scroll {
  width: 100%;
  overflow-x: auto;

  th {
    min-width: 130px;
  }

  td {
    white-space: nowrap;
  }
}

.vertical-align {
    display: flex;
    align-items: center;
}

.nav-tabs > li:not(.active) > a,
.nav-tabs > li:not(.active) > a:hover{
  background-color: #FFF !important;
  color: #024EA2 !important;
  border: 1px solid #024EA2 !important;
}
